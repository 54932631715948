import React, { useContext } from 'react'
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import Button, { ButtonStyle } from '../components/button'
import { linkResolver } from '../utils/linkResolver'
import { TranslationContext } from '@superrb/gatsby-addons/context'
import { StaticImage } from 'gatsby-plugin-image'

const NotFoundPage = () => {
  const { translate } = useContext(TranslationContext)

  return (
    <div className="error-page error-page--404">
      <StaticImage
        className="error-page__image"
        src="../images/404.png"
        placeholder="blurred"
        alt=""
      />
      <h1 className="error-page__title">{translate('404.title')}</h1>
      <p className="error-page__text">{translate('404.text')}</p>
      <Button
        className="error-page__button"
        href="/"
        label={translate('404.button_label')}
        buttonStyles={[ButtonStyle.primary, ButtonStyle.arrow]}
      />
    </div>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)
